import React from 'react';
import Slider from 'react-slick';
import PreLoadImage from './PreLoadImage'; // PreLoadImage 컴포넌트를 임포트하는 위치에 맞게 수정
import Config from '../Config'; // Config 모듈의 경로에 맞게 수정
import images from '../libs/images'; // images 모듈의 경로에 맞게 수정

const PcHomeProgram = ({ list, sliderRef1, settings, scrollPos, mobile, setList }) => {

  const formatDate1 = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  return (
    <Slider ref={sliderRef1} {...settings} className="slickWrap">
      {list.map((data, index) => {

        const now = new Date();
        const sDateTime = new Date(data.s_date_time);
        const diffInTime = sDateTime.getTime() - now.getTime();
        const diffInDays = diffInTime / (1000 * 3600 * 24);
        const isCloseToEnd = data.expire === 0 && (data.cnt - data.cnt_temp <= Math.max(data.cnt * 0.1, 3));
        const isSoldOut = data.cnt <= data.cnt_temp;
        return (
          <div key={index} className={scrollPos > (mobile ? -30 : -30) ? 'slidePar programImage fade-up-ani ani-delay-' + (100 * (index + 2)) : 'slidePar programImage'}>
            <button
              className='relative ofh'
              onMouseOver={() => {
                list[index].hover = 1;
                setList([...list]);
              }}
              onMouseOut={() => {
                list[index].hover = 0;
                setList([...list]);
              }}
              onClick={() => window.location = '/programDetail?id=' + data.id}
            >
              <PreLoadImage className={'bg-img1'} url={Config.IMG_URL + data.img}  data={data}/>
              {(data.start === 1 && data.expire === 1) && <span className='status bg999'>판매완료</span>}
              {data.expire === 0 && <span className='status'>신청진행중</span>}
              <div className='b-txt'>
                <p className='homeTitle'><span className="TitleName">{data.name}</span><span class="styleWhite">-</span>

                  {(() => {
                    const publishDate = new Date(data.publish_date); 
                    const currentDate = new Date(); 
                    const diffInDays = Math.floor((currentDate - publishDate) / (1000 * 60 * 60 * 24)); 

                    if (diffInDays >= 0 && diffInDays <= 7) {
                      return <><span className="newbadge">NEW</span><span class="styleWhite">-</span></>
                    }
                  })()}
                {isSoldOut && <div className="purpleNameBtn">전석매진</div>}
                {isCloseToEnd && !isSoldOut && data.always !="Y" &&  　<div className="redNameBtn">마감임박</div>}</p>
                <p className='homeShort'>{data.short}</p>
                <p className='homeLast'>
                  <span className='LastInner'>{data.cat_name}</span>
                  <span className='LastDivider'>|</span>
                  {data.healer_name !== '' && (
                    <>
                      <span className='LastInner'>{data.healer_name}</span>
                      <span className='LastDivider'>|</span>
                    </>
                  )}
                  <span className='LastInner'>{data.always === "Y" ? '상시신청' : '진행일시 ' + formatDate1(data.s_date)}</span>
                </p>
              </div>
            </button>
          </div>
        )
      })}
    </Slider>
  );
};

export default PcHomeProgram;