const data = {
  getAuthNum: '/getAuthNum',
  getBankList: '/getBankList',
  getSidoList: '/getSidoList',
  getSigunguList: '/getSigunguList',
  checkEmail: '/checkEmail',
  checkPhone: '/checkPhone',
  checkNickName: '/checkNickName',
  checkPassword: '/checkPassword',
  login: '/login',
  register: '/register',
  getUserInfoByPhone: '/getUserInfoByPhone',
  getUserInfoByEmailPhone: '/getUserInfoByEmailPhone',
  getUserInfoById: '/getUserInfoById',
  updatePassword: '/updatePassword',
  updateUserInfo: '/updateUserInfo',
  exitUser: '/exitUser',
  getFaqTypeList: '/getFaqTypeList',
  getFaqList: '/getFaqList',
  getAbout: '/getAbout',
  getWebzineIntro: '/getWebzineIntro',
  getTodayWebzineAllList: '/getTodayWebzineAllList',
  getTodayWebzineSectionList: '/getTodayWebzineSectionList',
  getWebzineList: '/getWebzineList',
  getWebzineCnt: '/getWebzineCnt',
  getSearchWebzineList: '/getSearchWebzineList',
  getSearchWebzineCnt: '/getSearchWebzineCnt',
  getWebzineById: '/getWebzineById',
  increaseWebzineViewCnt: '/increaseWebzineViewCnt',
  otherWebzineList: '/otherWebzineList',
  getWebzineGood: '/getWebzineGood',
  webzineAddGood: '/webzineAddGood',
  webzineDelGood: '/webzineDelGood',
  getWebzineExpect: '/getWebzineExpect',
  webzineAddExpect: '/webzineAddExpect',
  webzineDelExpect: '/webzineDelExpect',
  getWebzineSad: '/getWebzineSad',
  webzineAddSad: '/webzineAddSad',
  webzineDelSad: '/webzineDelSad',
  getWebzineAngry: '/getWebzineAngry',
  webzineAddAngry: '/webzineAddAngry',
  webzineDelAngry: '/webzineDelAngry',
  getCategoryList: '/getCategoryList',
  getProgramList: '/getProgramList',
  getProgramCnt: '/getProgramCnt',
  getSearchProgramList: '/getSearchProgramList',
  getSearchProgramCnt: '/getSearchProgramCnt',
  getProgramById: '/getProgramById',
  checkAttentionProgram: '/checkAttentionProgram',
  addAttentionProgram: '/addAttentionProgram',
  delAttentionProgram: '/delAttentionProgram',
  checkRequestProgram: '/checkRequestProgram',
  getProgramReviewList: '/getProgramReviewList',
  getReviewProgramList: '/getReviewProgramList',
  addReview: '/addReview',
  getReviewById: '/getReviewById',
  checkRecommendReview: '/checkRecommendReview',
  addRecommendReview: '/addRecommendReview',
  delRecommendReview: '/delRecommendReview',
  addReviewComment: '/addReviewComment',
  delReviewComment: '/delReviewComment',
  addScrap: '/addScrap',
  delScrap1: '/delScrap1',
  delScrap2: '/delScrap2',
  checkScrap: '/checkScrap',
  getReviewCommentList: '/getReviewCommentList',
  getHomeReviewList: '/getHomeReviewList',
  getReviewList: '/getReviewList',
  getReviewCnt: '/getReviewCnt',
  getSearchReviewList: '/getSearchReviewList',
  getSearchReviewCnt: '/getSearchReviewCnt',
  getReviewInfo: '/getReviewInfo',
  getRequestProgramList: '/getRequestProgramList',
  getRequestProgramCnt: '/getRequestProgramCnt',
  getAttentionProgramList: '/getAttentionProgramList',
  getAttentionProgramCnt: '/getAttentionProgramCnt',
  getNoticeList: '/getNoticeList',
  getNoticeCnt: '/getNoticeCnt',
  increaseNoticeViewCnt: '/increaseNoticeViewCnt',
  getNoticeById: '/getNoticeById',
  getHomeInfo: '/getHomeInfo',
  getProductList: '/getProductList',
  getProductById: '/getProductById',
  addPayment: '/addPayment',
  getProgramScheduleList: '/getProgramScheduleList',
  getProgramScheduleList1: '/getProgramScheduleList1',
  getProgramScheduleCnt: '/getProgramScheduleCnt',
  getPrimeCalendarList: '/getPrimeCalendarList',
  getProgressProgramList: '/getProgressProgramList',
  getPaymentInfo: '/getPaymentInfo',
  getAlimList: '/getAlimList',
  getAlimCnt: '/getAlimCnt',
  delAllAlim: '/delAllAlim',
  delAlim: '/delAlim',
  readAlim: '/readAlim',
  getPaymentList: '/getPaymentList',
  getPaymentCnt: '/getPaymentCnt',
  requestRefund: '/requestRefund',
  getPaymentById: '/getPaymentById',
  getTerms: '/getTerms',
  getNoticePopupList: '/getNoticePopupList',
  getBannerInfo: '/getBannerInfo',
  requestPay1: '/requestPay1',
  requestPay2: '/requestPay2',
  addInquiry: '/addInquiry',
  getInquiryList: '/getInquiryList',
  getInquiryCnt: '/getInquiryCnt',
  getMyReviewList: '/getMyReviewList',
  getMyReviewCnt: '/getMyReviewCnt',
  getMyScrapList: '/getMyScrapList',
  getMyScrapCnt: '/getMyScrapCnt',
getProgramHealerList : "/getProgramHealerList",
  delReview : "/delReview",
  getAllProgramList:"/getAllProgramList",
  getWebzineCommentList : '/getWebzineCommentList',
  addWebzineComment : '/addWebzineComment',
  delWebzineComment : '/delWebzineComment',
  updateReview : '/updateReview',
  getProgramRelatedReviewList : '/getProgramRelatedReviewList',
  getScheduleList : "/getScheduleList",
  getPrimeCalendarAddList : "/getPrimeCalendarAddList",
  getProgramByIdTest:"/getProgramByIdTest",
  getProgramListTest:"/getProgramListTest",
  getProgramAlwaysList:"/getProgramAlwaysList",
  getProgramBeforeList:"/getProgramBeforeList",
  getProgramCategoryList:"/getProgramCategoryList",
  requestPayTest2 : "/requestPayTest2",
  checkOrderId : "/checkOrderId",
  kakaoTest : "/kakaoTest"
  
};

export default data;